<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="list-of-offers">
    <div v-if="offers !== null">
      <!-- <el-row type="flex" class="list-of-offers__search">
        <el-input
          v-model="search"
          size="mini"
          placeholder="Поиск по коммерческим предложениям"
        />
        <el-button
          @click="getSearch"
          size="mini"
          type="primary"
          icon="el-icon-search"
        ></el-button>
      </el-row> -->
      <el-table :data="
        offers.filter(
          (data) =>
            data.nameCompany !== null &&
            (!search ||
              data.nameCompany.toLowerCase().includes(search.toLowerCase()) ||
              data.managerName.toLowerCase().includes(search.toLowerCase()) ||
              data.productName.toLowerCase().includes(search.toLowerCase()))
        )
      " :default-sort="{ prop: 'date', order: 'ascending' }" stripe style="width: 100%">
        <el-table-column prop="date" min-width="120" sortable :sort-method="sortDate" label="Дата">
        </el-table-column>
        <el-table-column min-width="200" label="Компания">
          <!-- eslint-disable-next-line -->
          <template slot-scope="scope">
            <span v-html="scope.row.nameCompany"></span>
          </template>
        </el-table-column>
        <el-table-column min-width="90" prop="brand" label="Бренд">
        </el-table-column>
        <el-table-column min-width="90" prop="model" label="Модель">
        </el-table-column>
        <el-table-column min-width="150" v-if="userId" prop="managerName" sortable label="Менеджер"></el-table-column>
        <el-table-column fixed="right" width="60">
          <!-- eslint-disable-next-line -->
          <template slot-scope="scope">
            <!-- <el-button size="mini" icon="el-icon-edit" type="success" circle @click="onClickEdit(scope)"></el-button> -->
            <el-button size="mini" icon="el-icon-view" circle @click="onClickView(scope)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background layout="prev, pager, next" @current-change="pageChange" :current-page="currentPage"
        :page-count="pageCount">
      </el-pagination>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import moment from "moment";
import Fetch from "./mixins/Fetch";
import { ADRESS } from "@/store/actions/auth";
import { handelerErrors } from "@/plugins/utils"

export default {
  name: "AllOffers",
  components: {},
  mixins: [Fetch],
  data() {
    return {
      offers: null,
      search: "",
      currentPage: 1,
      pageCount: 1,
      fullscreenLoading: false,
      activities: null,
      userId: "",
    };
  },
  methods: {
    fetchData() {
      this.activities = localStorage.getItem("user-token");
      this.userId = localStorage.getItem("used-id");
      this.getAllOffers();
    },
    sortDate(a, b) {
      return (
        moment(a, "DD.MM.YYYY HH:mm").unix() -
        moment(b, "DD.MM.YYYY HH:mm").unix()
      );
    },
    getAllOffers() {
      this.fullscreenLoading = true;
      axios({
        method: "get",
        url: `${ADRESS}all-offers-get/`,
        params: { page: this.currentPage, documentCount: 100 },
        headers: { Authorization: this.activities },
      })
        .then((response) => {
          this.offers = response.data.offers;
          this.pageCount = response.data.pageCount;
          this.fullscreenLoading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.fullscreenLoading = false;
          handelerErrors(err);
        });
    },
    pageChange(page) {
      this.currentPage = page;
      this.getAllOffers();
    },
    getSearch() {
      this.fullscreenLoading = true;
      axios
        .get(`${ADRESS}/all-offers-get/` + "?page=" + this.currentPage, {
          headers: { Authorization: this.activities },
        })
        .then((response) => {
          this.offers = response.data.offers;
          this.pageCount = response.data.pageCount;
          this.currentPage = response.data.currentPage;
          this.fullscreenLoading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.fullscreenLoading = false;
          handelerErrors(err);
        });
    },
    onClickView(scope) {
      const uri = "/create-offer?open-offer=" + scope.row.id + "&is-edit=" + false;
      window.open(uri, "_blank").focus();
    },
    onClickEdit(scope) {
      const uri = "/create-offer?open-offer=" + scope.row.id + "&is-edit=" + true;
      window.open(uri, "_blank").focus();
    },
  },
};
</script>

<style lang="scss">
.list-of-offers {
  .el-pagination {
    margin: 20px 0;
    display: flex;
    justify-content: center;
  }

  .el-table {
    margin-top: 30px;
    font-size: 12px;

    @media (max-width: 550px) {
      margin-top: 15px;
    }

    .el-table__row {
      cursor: pointer;
    }
  }

  .list-of-offers__search {
    margin-top: 15px;
  }
}
</style>
